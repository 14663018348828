@import '../../Assets/less/style.less';

.terms_bg{
    min-height: 100vh;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('../../Assets/Images/terms_bg.png');
    padding-top: 100px;
}

