@base-font: 16px;

// Custom
@padding-x: 32px;
@primary: #000000;
@primary-invert: #ffffff;
@secondary: #777777;
@secondary-invert: #ffffff;
@inactive: #bbbbbb;
@white: #ffffff;
@border-radius:8px;
@icn-bg:#e3e3e3;
@gray:#e3e3e3;
@input-bg:#f2f2f2;
@off-white:rgba(255,255,255,0.6);
@border-color:#e3e3e3;
@card-bg:#ffffff;
@login-inp-bg:rgba(187, 187, 187, .36);
@result:#fa7979;
@red:#ff4141;
@yellow:#f1aa1d;
@purple:#6c63ff;
@green:#26d65b;
@blue:#00b1ff;
@dark:#333333;
@darkgreen: #004225;
@warning-bg:rgba(241, 170, 29, 0.2);
@active-bg:rgba(38, 214, 91, 0.2);
@danger-bg:rgba(255, 65, 65, 0.2);

// Colors
@body-bg: #f2f2f2;
@footer-clr:#777777;
@sider-bg:#f2f2f2;
@header-bg:#ffffff;
@font-size-base: 1rem;
@h1-font-size: @font-size-base * 3.25;
@h2-font-size: @font-size-base * 1.9;
@h3-font-size: @font-size-base * 1;
@h4-font-size: @font-size-base * 0.875;

html,
body,
#root,
.ant-layout,.roboto {
  // height: 100%;
  font-family: 'Roboto', sans-serif;
}
.ant-layout {
  background-color: @body-bg !important;
}
.icon-8,
.icon-10,
.icon-11,
.icon-12,
.icon-13,
.icon-14,
.icon-15,
.icon-16,
.icon-18,
.icon-20,
.icon-24,
.icon-28,
.icon-32,
.icon-40,
.icon-60,
.icon-80,
.icon-90,
.icon-100,
.icon-150 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  height: 100%;

  svg {
    display: block;
    width: 100%;
    fill: currentColor;
    height: 100%;
  }
}

.icon-8 {
  width: 8px;
  height: 8px;
}
.icon-10 {
  width: 10px;
  height: 10px;
}

.icon-11 {
  width: 11px;
  height: 11px;
}

.icon-12 {
  width: 12px;
  height: 12px;
}

.icon-13 {
  width: 13px;
  height: 13px;
}

.icon-14 {
  width: 14px;
  height: 14px;
}

.icon-15 {
  width: 15px;
  height: 15px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-28 {
  width: 28px;
  height: 28px;
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-60 {
  width: 60px;
  height: 60px;
}

.icon-80 {
  width: 80px;
  height: 80px;
}

.icon-90 {
  width: 90px;
  height: 90px;
}

.icon-100 {
  width: 100px;
  height: 100px;
}

.icon-150 {
  width: 150px;
  height: 150px;
}

.svg-icon-hide {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.r-regular{
  font-weight: 400 !important;
}
.r-medium{
  font-weight: 500 !important;
}
.r-bold{
  font-weight: 700 !important;
}
.r-italic{
  font-weight: 500;
  font-style: italic;
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px;
}
.fs-20{
  font-size: 20px;
}
.fs-24{
  font-size: 24px !important;
}
.small_icon{
  min-width: 17px;
  width: 17px;
  height: 17px;
  background: @off-white;
  color: @primary;
}
.off-white{
  color: @off-white !important;
}
.customLink{
  color: @off-white;
  &:hover{
    color: @white;
  }
  &:hover .small_icon{
    background: @white;
  }
}
.border-radius{
  border-radius: @border-radius;
}
.primary{
  color: @primary !important;
}
.h-40{
  height: 40px;
}
.modal_close{
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: rgba(255,255,255,0.1) !important;
  color: rgba(255,255,255,0.4) !important;
  margin-top: 13px;
}
.cmn_modal_wrapper{
  .ant-modal-header{
      padding: 18px 18px 0 18px;
      border-bottom: 0;
      background: #000;
      .ant-modal-title{
          font-weight: 700;
          font-size: 24px;
          color: @primary-invert;
      }
  }
  .ant-modal-body{
      padding: 18px;
  }
  .ant-modal-footer{
    border-top: 0;
  }
  .ant-form-item-label{
    label{
      color: #BBBBBB;
      font-weight: 500 !important;
      text-transform: uppercase;
    }
  }
}

.inp_style{
  background: rgba(255,255,255,0.1) !important;
  border-radius: @border-radius !important;
  color: rgba(255,255,255,0.4) !important;
}

.p_section{
  max-height: 500px;
  overflow-y: auto;
}