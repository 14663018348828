@import '../../Assets/less/style.less';

.cloudPage_bg {
    min-height: 100vh;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('../../Assets/Images/privacy_bg.png');
    padding-top: 100px;
}

.cloud_min_ht{
    min-height: calc(100vh - 200px);
}
