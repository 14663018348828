@import '../../Assets/less/style.less';
#footer{
  position: fixed;
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  height: 72px;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 768px){
    &{
      position: relative;
      padding-bottom: 30px;
      height: auto;
      background: #000;
    }
  }
}
.ftr_link:hover{
  color: @primary-invert;
}
