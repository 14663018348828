@import '../../Assets/less/style.less';

.customCard{
    height: 100vh;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}
.customCardbg{
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url('../../Assets/Images/vms-shop.png');
}
.customCardbg2{
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url('../../Assets/Images/vms-voult.png');
}
.customCardbg3{
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url('../../Assets/Images/vms.png');
}

.brand_wrapper{
    max-width: 150px;
    margin: 0 auto;
    width: 100%;
}
